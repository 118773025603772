<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">
          Application Setup Complete
        </v-card-title>
        <v-card-text
          >All done! Click the Log in link below to sign in to your
          application!</v-card-text
        >
        <v-card-actions>
          <v-spacer />
          <v-btn color="green darken-1" text :href="link"> Log In </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'OnboardSuccessDialog',
    mixins: [],
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      link: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        dialog: false,
      };
    },
    computed: {},
    watch: {
      value: function () {
        this.dialog = this.value;
      },
    },
    created() {},
    mounted() {},
    methods: {
      closeDialog() {
        this.dialog = false;
        this.$emit('input', false);
      },
      login() {},
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped></style>

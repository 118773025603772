// src/services/System.service.js

import axios from 'axios';
import {uuid} from 'vue-uuid';

export default {
  blankSubscription() {
    var subscription = {
      uuid: uuid.v4(),
      tenant_uuid: null,
      first_name: '',
      last_name: '',
      email: '',
      company: '',
      customer_id: '',
      subscription_id: '',
      product_code: '',
      plan_code: '',
      active: 'INACTIVE',
    };
    return subscription;
  },
  created() {
    // handle api call errors
    axios.interceptors.response.use(
      (response) => response,
      (err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log(err);
        }
      }
    );
  },

  // *****************************************************/
  //                      GET BY UUID
  // *****************************************************/

  // [ SUBSCRIPTIONS ] FIND BY TENANT UUID
  async loadSubscriptionByTenantUUID(uuid, accessToken) {
    var res = await axios({
      method: 'GET',
      url: '/service_system/subscriptions/bytenant/' + uuid,
      headers: {Authorization: `Bearer ${accessToken}`},
    });

    return res.data;
  },

  // *****************************************************/
  //                      GET BY PARAMS
  // *****************************************************/

  // [ SUBSCRIPTIONS ]
  // NOTE: UNSECURED! this is needed for onboarding, so
  // no cookie will be used.
  // TODO: limit the response to not include the entire
  // datablock and only what is necessary
  async getSubscriptionsByEmail(params) {
    var res = await axios({
      method: 'GET',
      url: '/service_system/subscriptions/email',
      params: params,
    });
    return res.data;
  },

  async getConfigurations(params, accessToken) {
    var res = await axios({
      method: 'GET',
      url: '/service_system/configurations/all',
      params: params,
      headers: {Authorization: `Bearer ${accessToken}`},
    });

    return res.data;
  },

  // *****************************************************/
  //                      UPDATE
  // *****************************************************/

  // [ SUBSCRIPTIONS ]
  // note: unsecured due to onboarding
  async updateSubscription(uuid, data) {
    let res = await axios({
      method: 'PUT',
      url: '/service_system/subscriptions/' + uuid,
      data: data,
    });

    if (res) {
      return true;
    } else {
      return false;
    }
  },

  // *****************************************************/
  //                    CREATE
  // *****************************************************/

  // [ HUBSPOT ]
  async createHubspotIntegration(data) {
    let res = await axios({
      method: 'POST',
      url: '/service_system/hubspot/contact',
      data: data,
    });
    return res.data;
  },

  async createCustomerPortalSession(data, accessToken) {
    let res = await axios({
      method: 'POST',
      url: '/service_system/stripe/customer_portal',
      headers: {Authorization: `Bearer ${accessToken}`},
      data: data,
    });
    return res.data;
  },

  // [ CHEDDAR ]
  // Note: unsecured due to onboarding
  async createCheddarCustomer(data) {
    let res = await axios({
      method: 'POST',
      url: '/service_system/cheddar/customer/create',
      data: data,
    });

    if (res) {
      return true;
    } else {
      return false;
    }
  },

  // [ SUBSCRIPTIONS ]
  // Note: unsecured due to onboarding
  async createSubscription(data) {
    let res = await axios({
      method: 'POST',
      url: '/service_system/subscriptions/create',
      data: data,
    });

    if (res) {
      return true;
    } else {
      return false;
    }
  },
};

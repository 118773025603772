<template>
  <v-row>
    <v-col
      class="display-2"
      align="center"
      justify="center"
      v-bind:class="[!yearlySubscription ? 'active-plan' : 'inactive-plan']"
      @click="setMonthly()">
      $95 / month
    </v-col>
    <v-col
      class="display-2"
      align="center"
      justify="center"
      v-bind:class="[yearlySubscription ? 'active-plan' : 'inactive-plan']"
      @click="setYearly()">
      $995 / year
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'PaymentSelector',
    props: {},
    data() {
      return {
        yearlySubscription: false,
      };
    },
    methods: {
      setMonthly() {
        this.yearlySubscription = false;
        this.$emit('subscriptionChange', false);
      },
      setYearly() {
        this.yearlySubscription = true;
        this.$emit('subscriptionChange', true);
      },
    },
  };
</script>

<style scoped>
  .active-plan {
    border-radius: 25px;
    border: 3px solid #95c355;
    padding: 20px;
    margin: 0.5em;
  }
  .inactive-plan {
    border-radius: 25px;
    border: 3px solid #cdcdcd;
    padding: 20px;
    margin: 0.5em;
  }
</style>

<!-- ----------------------------------------------------------------------- -->
<!--
name    : ONBOARD

type    : view

uses    : error-dialog
          onboard-success-dialog
          progress-dialog

route   : /onboard (default child route)
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <v-container class="onboard-view">
    <v-card color="grey lighten-4" min-width="350px" flat>
      <v-toolbar height="100" src="/img/jobs-onboarding-header.png">
        <template v-slot:img="{props}">
          <v-img
            v-bind="props"
            aspect-ratio="8.57142"
            style="background-size: cover"></v-img>
        </template>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col class="display-1"> Company Info: </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  id="company"
                  ref="company_name"
                  v-model="company_name"
                  label="Company Name"
                  :rules="validate_tenant_name"
                  :error="nameUniqueError"
                  :error-messages="nameUniqueMessage"
                  v-on:blur="handleBlurName"
                  outlined
                  hint="" />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  id="address"
                  v-model="address"
                  label="Address"
                  :rules="validate_address"
                  outlined
                  hint="" />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="city"
                  label="City"
                  :rules="validate_address"
                  outlined
                  hint="" />
              </v-col>
              <v-col>
                <v-select
                  v-model="state"
                  label="State/Region"
                  :items="states"
                  outlined
                  hint="" />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-select
                  id="country"
                  v-model="country"
                  :items="countryItems"
                  outlined
                  label="Country"
                  hint="" />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="zip_code"
                  label="Postal Code"
                  :rules="validate_zip_code"
                  outlined
                  hint="" />
                <!--  -->
              </v-col>
            </v-row>
            <!-- 12/21 - Disabling custom subdomains per issue: TP2-461  -->
            <!-- <v-row dense>
              <v-col>
                <v-text-field
                  v-model="company_url"
                  label="Application URL"
                  :rules="[newTenantURLRules.required, newTenantURLRules.match]"
                  outlined
                  hint=""
                  :suffix="currentDomain"
                  :error="tenantUniqueError"
                  :error-messages="tenantUniqueMessage" />
              </v-col>
            </v-row> -->
            <v-row>
              <v-col class="display-1"> Contact/User Info: </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  id="first_name"
                  v-model="first_name"
                  label="First name"
                  :rules="validate_contact_name"
                  outlined />
              </v-col>
              <v-col>
                <v-text-field
                  id="last_name"
                  v-model="last_name"
                  label="Last Name"
                  :rules="validate_contact_name"
                  outlined />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  outlined
                  ref="email"
                  v-model="email"
                  label="Email Address"
                  :rules="emailRules"
                  :error="emailUniqueError"
                  :error-messages="emailUniqueMessage"
                  v-on:blur="handleBlurEmail"
                  :disabled="disableEmail" />
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  id="phone"
                  v-model="phone"
                  label="Phone"
                  :rules="validate_contact_phone" />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="password"
                  :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[passwordRules.required, passwordRules.regex]"
                  :type="showPass1 ? 'text' : 'password'"
                  name="input-10-2"
                  label="Enter Password"
                  hint="Enter your password"
                  class="input-group--focused"
                  @click:append="showPass1 = !showPass1"
                  autocomplete="password">
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="password2"
                  :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[
                    passwordRules.required,
                    passwordRules.regex,
                    passwordsMatch,
                  ]"
                  :type="showPass2 ? 'text' : 'password'"
                  name="input-10-2"
                  label="Confirm Password"
                  hint="Confirm your password"
                  @click:append="showPass2 = !showPass2"
                  autocomplete="password">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="display-1">
              Pricing Plans
            </v-row>
            <v-row align="center" justify="center">
              <h3>All Subscriptions have a 14 Day Free Trial Period</h3>
            </v-row>
            <payment-selector
              v-model="yearlySubscription"
              @subscriptionChange="changeYearly" />
            <v-row justify="center">
              <div class="tos-container">
                <!-- :rules="tosRules.required" -->
                <v-checkbox
                  v-model="tosCheck"
                  class="tos-agreement"
                  :rules="[
                    (v) =>
                      !!v ||
                      'Accepting the Terms of Service and Privacy Policy is required to proceed',
                  ]">
                  <template v-slot:label>
                    <div>
                      I have read and agreed to the
                      <a
                        v-bind:href="clientConfigurations.legal.tos_url"
                        target="_blank"
                        @click.stop
                        >Terms of Service</a
                      >
                      and
                      <a
                        v-bind:href="clientConfigurations.legal.pp_url"
                        target="_blank"
                        @click.stop
                        >Privacy Policy</a
                      >
                    </div>
                  </template>
                </v-checkbox>
              </div>
              <span></span>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="phase-green mx-auto"
          dark
          x-large
          max-width="600"
          min-width="400"
          text
          :disabled="isDisabled"
          @click="onboardSubmit()">
          Get Started!
        </v-btn>
      </v-card-actions>
    </v-card>
    <error-dialog
      v-model="errorDialog"
      :header="errorHeader"
      :body="errorBody" />
    <onboard-success-dialog v-model="successDialog" :link="successLink" />
    <progress-dialog
      v-model="progressDialog"
      header="Processing"
      body="Processing" />
  </v-container>
</template>

<script>
  // components
  import ErrorDialog from '@/components/helpers/ErrorDialog';
  import OnboardSuccessDialog from '@/components/onboard/OnboardSuccessDialog';
  import ProgressDialog from '@/components/helpers/ProgressDialog';
  import PaymentSelector from '@/components/payment/PaymentSelector';

  // mixins
  import forms from '@/mixins/forms';
  import Localization from '@/mixins/localization';
  import {states} from '@/mixins/constants';

  // services
  import Users from '@/services/Users.service.js';
  import Tenants from '@/services/Tenants.service.js';
  import System from '@/services/System.service.js';
  import Stripe from '@/services/Stripe.service.js';

  /* eslint-enable */

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Onboard',
    components: {
      ErrorDialog,
      OnboardSuccessDialog,
      ProgressDialog,
      PaymentSelector,
    },
    mixins: [forms, Localization],
    props: {},
    data() {
      return {
        address: '',
        city: '',
        company_name: '',
        company_url: null,
        country: '',
        // currentDomain: '',
        disableEmail: false,
        email: '',
        emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'Must be a valid email address',
        ],
        errorBody: '',
        errorDialog: false,
        errorHeader: '',
        first_name: '',
        last_name: '',
        phone: '',
        progressDialog: false,
        progressStatus: '',
        showPass1: false,
        showPass2: false,
        password: '',
        password2: '',
        passwordRules: {
          required: (value) => !!value || 'Password is required',
          regex: (v) => {
            // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
            // https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
            if (
              !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                v
              )
            ) {
              return 'Password must have a minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character: @$!%*?&';
            }
            return true;
          },
        },
        tosCheck: false,
        states: states,
        state: '',
        subscription: {},
        subscriptions: null,
        successDialog: false,
        successLink: '',
        tenantUniqueError: false,
        tenantUniqueMessage: '',
        user: {},
        valid: false,
        workCategory: {},
        zip_code: '',
        emailUniqueError: false,
        emailUniqueMessage: '',
        nameUniqueError: false,
        nameUniqueMessage: '',
        clientConfigurations: {},
        yearlySubscription: false,
      };
    },
    computed: {
      passwordsMatch: function () {
        return (
          (this.password && this.password === this.password2) ||
          'The passwords must match'
        );
      },
      isDisabled: function () {
        return (
          !(this.password && this.password === this.password2) ||
          !this.company_name ||
          !this.address ||
          !this.city ||
          !this.state ||
          !this.zip_code ||
          // !this.company_url ||    12/21 - Disabling custom subdomains per issue: TP2-461
          !this.first_name ||
          !this.last_name ||
          !this.email ||
          this.emailUniqueError ||
          !this.phone ||
          !this.tosCheck
        );
      },
    },
    watch: {
      /*
     12/21 - Disabling custom subdomains per issue: TP2-461
      company_url: async function (after, before) {
        if (after) {
          var tenants = await Tenants.checkIfUrlIsTaken({
            tenant_url: after,
          });
          if (tenants) {
            if (tenants.length > 0) {
              this.tenantUniqueError = true;
              this.tenantUniqueMessage = 'URL Not available';
            } else {
              this.tenantUniqueError = false;
              this.tenantUniqueMessage = '';
            }
          } else {
            this.tenantUniqueError = true;
            this.tenantUniqueMessage = 'Error';
            this.errorHeader = 'An error has occurred';
            this.errorBody = 'There was an error communicating with the server';
            this.errorDialog = true;
          }
        }
      },

      company_name: function (after, before) {
        //if tenant url has not been manually modified, update it when the company name is changed
        if (this.company_url == this.generateURL(before)) {
          this.company_url = this.generateURL(after);
        }
      },
      */
    },
    async mounted() {
      this.clientConfigurations = this.$getConfig().client_configurations;

      this.$refs.form.reset();
      // 12/21 - Disabling custom subdomains per issue: TP2-461
      // populate what the current domain is, used in displayed links
      // this.getCurrentDomain();

      //Populate all of the form using any query params passed
      // console.log( this.$route.query )
      if (this.$route.query.hasOwnProperty('email')) {
        this.email = this.$route.query.email;
      }
      if (this.$route.query.hasOwnProperty('company')) {
        this.company_name = this.$route.query.company;
      }
      if (this.$route.query.hasOwnProperty('phone')) {
        this.phone = this.$route.query.phone;
      }
      if (this.$route.query.hasOwnProperty('address')) {
        this.address = this.$route.query.address;
      }
      if (this.$route.query.hasOwnProperty('city')) {
        this.city = this.$route.query.city;
      }
      if (this.$route.query.hasOwnProperty('state')) {
        this.state = this.$route.query.state;
      }
      if (this.$route.query.hasOwnProperty('country')) {
        this.country = this.$route.query.country;
      }
      if (this.$route.query.hasOwnProperty('zip_code')) {
        this.zip_code = this.$route.query.zip_code;
      }

      // if email was passed, see if there is already a subscription map and load it instead of using
      // a new blank one, this allows someone to continue the onboarding here if they initially used the
      // hosted cheddar forms
      // we also need to block the parts of the form that are already contained in the subscription record
      // to prevent it from not being in sync with cheddar
      if (this.email) {
        this.subscriptions = await System.getSubscriptionsByEmail({
          email: this.email.toLowerCase(),
        });

        if (this.subscriptions) {
          if (this.subscriptions.length > 0) {
            //set the current subscription to the one we found
            this.subscription = this.subscriptions[0];

            //prevent user from changing email address (MUST be in sync with cheddar/subscription)
            this.email = this.subscription.email.toLowerCase();
            this.disableEmail = true;
          } else {
            //load blank subscription object
            this.subscription = System.blankSubscription();
          }
        } else {
          this.errorHeader = 'Error';
          this.errorBody =
            'There was an issue connecting to the server, if this continues please contact support';
          this.errorDialog = true;
        }
      }

      // // if there is already a subscription map with a tenant_uuid, load the tenant and display the
      // // "done" form with the link, to prevent anyone from processing the onboarding form twice.
      // // if there is not a tenant_uuid, then we let the form process normally
      // if ( this.subscription.tenant_uuid ) {
      //   let success = false
      //   success = await Tenant.getTenant( this.subscription.tenant_uuid, accessToken )
      //   if ( success ) {
      //     this.successLink = this.generateTenantURL()
      //     this.successDialog = true
      //   } else {
      //     this.errorHeader = "Error"
      //     this.errorBody = "There was an issue connecting to the server, if this continues please contact support"
      //     this.errorDialog = true
      //   }
      // }
    },
    methods: {
      async handleBlurEmail() {
        // this will clear out email in use message if
        // the field was cleared. Email required error will stil display
        if (this.email == null || this.email == '') {
          this.emailUniqueError = false;
          this.emailUniqueMessage = '';
          return;
        }

        if (this.email && this.email.length > 0) {
          // if field has errors OTHER THAN the unique email check error,
          // don't bother running it against database as it doesn't meet validation
          // requirements
          if (
            this.emailUniqueError == false &&
            this.$refs.email &&
            this.$refs.email.hasError
          ) {
            return;
          }

          // find out if email is already taken
          var matchedUsers = await Users.checkIfEmailIsTaken(this.email);

          // if taken, update input field with custom error
          if (matchedUsers) {
            this.emailUniqueError = true;
            this.emailUniqueMessage =
              'This address is already in use by another user';
          } else {
            this.emailUniqueError = false;
            this.emailUniqueMessage = '';
          }
        }
      },
      async handleBlurName() {
        // this will clear out company name in use message if
        // the field was cleared. company name required error will stil display
        if (this.company_name == null || this.company_name == '') {
          this.nameUniqueError = false;
          this.nameUniqueMessage = '';
          return;
        }

        if (this.company_name && this.company_name.length > 0) {
          // if field has errors OTHER THAN the unique company_name check error,
          // don't bother running it against database as it doesn't meet validation
          // requirements
          if (
            this.nameUniqueError == false &&
            this.$refs.company_name &&
            this.$refs.company_name.hasError
          ) {
            return;
          }

          // find out if company_name is already taken
          var matchedTenants = await Tenants.checkIfTenantNameIsTaken(
            this.company_name
          );

          // if taken, update input field with custom error
          if (matchedTenants) {
            this.nameUniqueError = true;
            this.nameUniqueMessage =
              'This address is already in use by another user';
          } else {
            this.nameUniqueError = false;
            this.nameUniqueMessage = '';
          }
        }
      },
      async onboardSubmit() {
        //load blank tenant object
        var tenant = Tenants.blankTenant();

        //load blank user object
        var user = Users.blankUser();

        //load blank hubspot object
        var hubspotForm = {};

        this.progressStatus = 'Validating Information';
        this.progressDialog = true;

        // STEP 1: VERIFY ALL THE DATA WE HAVE IS VALID
        var success = false;
        this.$refs.form.validate();
        if (!this.valid) {
          this.errorHeader = 'Form not complete';
          this.errorBody = 'There are errors on the form, please correct.';
          this.errorDialog = true;
          this.progressDialog = false;
          return;
        }

        this.progressStatus = 'Building Application';

        // STEP 2: CREATE THE TENANT RECORD
        tenant.tenant_name = this.company_name;
        tenant.contact_name = this.first_name + ' ' + this.last_name;
        tenant.terms;

        // the database still needs a unique company url unless we
        // manually disable that, so the lines below have been created
        // for that purpose.
        // original line:
        tenant.tenant_url = await this.generateUniqueURL(
          this.company_name,
          tenant.uuid
        );

        tenant.account_status = 'Active';
        tenant.subscription_type = 'unlimited';

        tenant.contact_email = this.email;
        tenant.contact_phone = this.phone;
        tenant.address = this.address;
        tenant.city = this.city;
        tenant.state = this.state;
        tenant.country = this.country;
        tenant.tos_version = this.clientConfigurations.legal.tos_version;

        success = await Tenants.createTenant(tenant);
        if (!success) {
          this.errorHeader = 'An error has occurred';
          this.errorBody =
            'There was an error setting up the application (App)';
          this.progressDialog = false;
          this.errorDialog = true;
          return;
        }

        this.progressStatus = 'Adding User';

        // STEP 3: CREATE THE USER RECORD
        user.role = 'admin';
        user.email = this.email.toLowerCase();
        user.password = this.password;
        user.tenant_uuid = tenant.uuid;
        user.name = this.first_name + ' ' + this.last_name; //tlc TODO: swap for a computed value? makes sense.
        user.status = 'Active';
        success = await Users.createUser(user);
        if (!success) {
          this.errorHeader = 'An error has occurred';
          this.errorBody =
            'There was an error setting up the application (User)';
          this.progressDialog = false;
          this.errorDialog = true;
          return;
        }

        // STEP 4: LOAD DEFAULT CATALOG ENTRIES [HOURLY]
        this.workCategory = Users.blankWorkCategory();
        this.workCategory.tenant_uuid = tenant.uuid;
        this.workCategory.name = 'General Labor';
        this.workCategory.catalog = 'HOURLY';
        this.workCategory.pricing_method = 'HOURLY';
        this.workCategory.default_rate = '0';
        this.workCategory.status = 'Active';
        await Users.createWorkCategory(this.workCategory);

        this.workType = Users.blankWorkType();
        this.workType.tenant_uuid = tenant.uuid;
        this.workType.workcategory_uuid = this.workCategory.uuid;
        this.workType.name = 'Clean-up';
        this.workType.status = 'Active';
        this.workType.rate = '75';
        await Users.createWorkType(this.workType);

        this.workCategory = Users.blankWorkCategory();
        this.workCategory.tenant_uuid = tenant.uuid;
        this.workCategory.name = 'Pruning';
        this.workCategory.catalog = 'HOURLY';
        this.workCategory.pricing_method = 'HOURLY';
        this.workCategory.default_rate = '0';
        this.workCategory.status = 'Active';

        await Users.createWorkCategory(this.workCategory);

        this.workType = Users.blankWorkType();
        this.workType.tenant_uuid = tenant.uuid;
        this.workType.workcategory_uuid = this.workCategory.uuid;
        this.workType.name = 'Crown Pruning';
        this.workType.status = 'Active';
        this.workType.rate = '100';
        await Users.createWorkType(this.workType);

        this.workType = Users.blankWorkType();
        this.workType.tenant_uuid = tenant.uuid;
        this.workType.workcategory_uuid = this.workCategory.uuid;
        this.workType.name = 'Crown Thin';
        this.workType.status = 'Active';
        this.workType.rate = '150';
        await Users.createWorkType(this.workType);

        // STEP 5: LOAD DEFAULT CATALOG ENTRIES [VARIABLE]
        this.workCategory = Users.blankWorkCategory();
        this.workCategory.tenant_uuid = tenant.uuid;
        this.workCategory.name = 'Injection';
        this.workCategory.catalog = 'VARIABLE';
        this.workCategory.pricing_method = 'DBH';
        this.workCategory.default_rate = '0';
        this.workCategory.status = 'Active';
        await Users.createWorkCategory(this.workCategory);

        this.workType = Users.blankWorkType();
        this.workType.tenant_uuid = tenant.uuid;
        this.workType.workcategory_uuid = this.workCategory.uuid;
        this.workType.name = 'Trunk Injection';
        this.workType.status = 'Active';
        this.workType.rate = '10';
        await Users.createWorkType(this.workType);

        this.workCategory = Users.blankWorkCategory();
        this.workCategory.tenant_uuid = tenant.uuid;
        this.workCategory.name = 'Spray';
        this.workCategory.catalog = 'VARIABLE';
        this.workCategory.pricing_method = 'DBH';
        this.workCategory.default_rate = '0';
        this.workCategory.status = 'Active';
        await Users.createWorkCategory(this.workCategory);

        this.workType = Users.blankWorkType();
        this.workType.tenant_uuid = tenant.uuid;
        this.workType.workcategory_uuid = this.workCategory.uuid;
        this.workType.name = 'Chemical Spray';
        this.workType.status = 'Active';
        this.workType.rate = '5';
        await Users.createWorkType(this.workType);

        // STEP 6: LOAD DEFAULT CATALOG ENTRIES [FLAT]
        this.workCategory = Users.blankWorkCategory();
        this.workCategory.tenant_uuid = tenant.uuid;
        this.workCategory.name = 'Permits';
        this.workCategory.catalog = 'FLAT';
        this.workCategory.pricing_method = 'FLAT';
        this.workCategory.default_rate = '0';
        this.workCategory.status = 'Active';
        await Users.createWorkCategory(this.workCategory);

        this.workType = Users.blankWorkType();
        this.workType.tenant_uuid = tenant.uuid;
        this.workType.workcategory_uuid = this.workCategory.uuid;
        this.workType.name = 'Sidewalk Closure';
        this.workType.status = 'Active';
        this.workType.rate = '80';
        await Users.createWorkType(this.workType);

        this.progressStatus = 'Creating Subscription';

        // STEP 7 PUSH FORM DATA INTO HUBSPOT
        const trackingCode = this.$route.query.hutk
          ? this.$route.query.hutk
          : '';
        hubspotForm = {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email.toLowerCase(),
          company: this.company_name,
          address: this.address,
          city: this.city,
          phone: this.phone,
          state: this.state,
          country: this.country,
          postal_code: this.zip_code,
          tracking_code: trackingCode,
          uri: window.location.pathname,
        };
        System.createHubspotIntegration(hubspotForm).catch((err) => {
          console.warn('HS failed', err);
        });

        // STEP 8: IF EVERYTHING UP UNTIL THIS POINT WAS SUCCESSFUL, REDIRECT TO STRIPE
        let successUrl = window.location.origin + '/success';
        let cancelUrl = window.location.origin + '/cancel';
        if (trackingCode !== '') {
          successUrl = successUrl + '?hstk=' + trackingCode;
          cancelUrl = cancelUrl + '?hstk=' + trackingCode;
        }

        const productCode = !this.yearlySubscription
          ? this.clientConfigurations.stripe.monthly_price_code
          : this.clientConfigurations.stripe.yearly_price_code;

        const checkoutURL = await Stripe.getCheckoutSession(
          productCode,
          user.tenant_uuid,
          successUrl,
          cancelUrl
        );

        if (checkoutURL) {
          window.location.href = checkoutURL;
        }

        this.progressDialog = false;
        this.successDialog = true;
      },
      // the database still needs a unique company url unless we
      // manually disable that, so this function remains to create that
      // entry
      async generateUniqueURL(name, uuid) {
        if (name) {
          let url = name.replace(/[^a-zA-Z0-9_-]/g, '');

          // Since the tenant name/company name is required to be unique,
          // this is unlikely to happen, but the check is here just in case
          var tenants = await Tenants.checkIfUrlIsTaken({
            tenant_url: url,
          });

          // if company url is taken (unlikely to happen because tenant
          // name is required to be unique), use uuid for placeholder
          // if we need to correct stuff in the future, we can search for
          // db entries with a matching uuid and url field
          if (tenants && tenants.length > 0) {
            return uuid;
          } else {
            return url.toLowerCase();
          }
        }
      },
      changeYearly(yearly) {
        this.yearlySubscription = yearly;
        console.log(`yearly subscription: ${this.yearlySubscription}`);
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .tos-container {
    margin: 2%;
  }
</style>

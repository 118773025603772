import axios from 'axios';

export default {
  getCheckoutSession(price_id, tenant_uuid, success, cancel) {
    return axios({
      method: 'POST',
      url: '/service_system/stripe/checkout_session',
      data: {
        price_id,
        tenant_uuid: tenant_uuid,
        success_url: success,
        cancel_url: cancel,
      },
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(({data}) => {
        return data.session_url;
      })
      .catch((error) => {
        console.error('Error getting a checkout session', error);
        window.location.href = window.location.origin + '/error';
      });
  },
};
